@import url("https://use.typekit.net/rij1umk.css");

@font-face {
  font-display: block;
  font-family: "neue-haas-grotesk-display";
}

@font-face {
  font-family: "marks-and-spencer-regular";
  src: url("/fonts/marks-and-spencer/MarksAndSpencer-Light.ttf");
  font-weight: 400;
  font-variant: normal;
  font-display: block;
}

@font-face {
  font-family: "marks-and-spencer-bold";
  src: url("/fonts/marks-and-spencer/MarksAndSpencer-SemiBold.ttf");
  font-weight: 600;
  font-variant: normal;
  font-display: block;
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: neue-haas-grotesk-display, sans-serif;
  font-style: normal;
  font-weight: 500;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul,
ol {
  list-style: none;
  padding: 0;
}

/* A elements that don't have a class get default styles */
a {
  color: inherit;
  text-decoration: none;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

button {
  background: none;
  border: none;
  border-radius: 0;
  cursor: pointer;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    scroll-behavior: auto !important;
    transition-duration: 0.01ms !important;
  }
}

/* Focus visible */

html.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

html.js-focus-visible .focus-visible {
  outline: 3px solid #528deb;
}

body.overlay-open {
  overflow: hidden;
}

